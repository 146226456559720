

import Vue from 'vue'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// import zh from './zh.js'
import en from './en.js'
// 日文
import ja from './ja.js'
//越南
import vi from './yn.js'
//繁体
import zh_TW from './ft.js'
//韩语
import kr from './kr.js'

const messages = {
	en,
    ja,
    vi,
	kr,
    'zh-TW':zh_TW
	// zh,
	// ja,
	// hi,
	// fr,
	// es,
	// ko,
	// pt,
	// ar
}
let i18nConfig = {
	locale: localStorage.getItem('UNI_LOCALE') ?  localStorage.getItem('UNI_LOCALE') : 'ja',
	// 获取已设置的语言
	messages
}
const i18n = new VueI18n(i18nConfig)
export default i18n