import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../components/Error.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../components/Home.vue')
  },
  {
    path: '/usdt',
    name: 'usdt',
    component: () => import('../components/Usdt.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../components/List.vue')
  },
  {
    path: '/bankcard',
    name: 'bankcard',
    component: () => import('../components/Bankcard.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../components/Recharge.vue')
  },
  {
    path: '/rechargeDetails',
    name: 'rechargeDetails',
    component: () => import('../components/Recharge_details.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // 需要登录验证的路由
    if (isAuthenticated) {
      // 用户已登录，允许访问
      next();
    } else {
      // 用户未登录，跳转到登录页
      //next('/login');
    }
  } else {
    // 不需要登录验证的路由，直接允许访问
    next();
  }
});
export default router
