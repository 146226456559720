import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js';

import { Popup } from 'vant';
import { List } from 'vant';
import { Toast } from 'vant';

import VueClipboard from 'vue-clipboard2'


import i18n from './lang/index.js';
import 'vant/lib/index.css';

Vue.use(Popup);
Vue.use(List);
Vue.use(Toast);
Vue.use(VueClipboard)
Vue.config.productionTip = false
new Vue({
  router,i18n,store,
  render: h => h(App),
}).$mount('#app')
