export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },
        home: {
            h1: "デジタル暗号通貨銀行",
            h2: "友達を招待",
            h3: "銀行口座",
            h4: "貯蓄に参加する",
            h5: "選択されたウェルス マネジメント",
            h6: "ユーザープール",
            h7: "USDT口座",
            h8: "総生産量",
            h9: "有効なノード",
            h10: "参加者",
            h11: "所得",
            h12: "利益記録",
            h13: "住所",
            h14: "番号",
            h15: "よくある問題",
            h16: "参加するにはどうすればよいですか？",
            h17: "親愛なるユーザーの皆様,USDT ウォレットの貯蓄に参加したい場合は,プラットフォームのユーザー ルールを遵守することを前提として,プラットフォームの決定を厳密に実施し,プラットフォームのリスク部門の監督を受け入れる必要があります。自動的に預金権限を取得します. 参加へようこそ. ありがとう.",
            h18: "お金を引き出すにはどうすればいいですか?",
            h19: "毎日発生する収益は,自動的にアカウントの残高に入金されます.USDT の引き出しが成功すると,USDT ウォレット アカウントに自動的に追加されます.日本円の引き出しについては,オンライン カスタマー サービス センターに連絡して処理してください.あなたの参加とサポートのために。",
            h20: "収入の計算方法は？",
            h21: "正常に加入すると,貯蓄契約が収入の計算を開始します。",
            h22: "貯金の収入はどこから？",
            h23: "ユーザーが貯蓄に参加すると,貯蓄契約が有効になり,ユーザーは 0:00,6:00,12:00,18:00 の 1 日 4 回,利息の決済を受けることができます。 貯蓄契約が発効すると,ユーザーの資金は保管モードになり,プラットフォームは資金の安全性に責任を負います。 ユーザーアカウントの資金の安全を確保するために,プラットフォームのルールを厳守し,問題が発生した場合はオンラインカスタマーサービスセンターにご相談ください。",
            h24: "ボーナスはいくらですか?",
            h25: "私のプラットフォームを友達と共有できます。 彼が参加すると,紹介ボーナスとして彼の初回入金額の 20% を得ることができ,紹介手数料として常に彼の収入の 10% を得ることができます。 共有された公開リンクは,ホームページの右上隅にあります。",
            h26: "アカウント",
            h27: "総収入",
            h28: "今日の収益",
            h29: "財務収益",
            h30: "エキス",
            h31: "記録",
            h32: "利用可能残高",
            h33: "出金額",
            h34: "出金金額を入力してください",
            h35: "すべて",
            h36: "エキス",
            h37: "よくある問題",
            h38: "セキュリティ監査レポートがあります",
            h39: "相棒",
            h40: "私たちのビジネスパートナー",
            h41: "キャッシュカード番号の表示",
            h42: "前のページ",
            h43: "次のページ",
            h44: "すでに最初のページです",
            h45: "出金金額を入力してください",
            h46: "残高が不足しています",
            h47: "すでに最後のページです",
            h48: "収入",
            h49: "支出",
            h50: '過剰な操作です。後でもう一度お試しください。',
            h51: '監査報告',
            h52: '通貨を預けて利息を得る',
            h53: '注文取引',
            h54: '登録ボーナス',
            h55: '招待ボーナス',
            h56: '引き出し',
            h57: 'チャージ',
            h58: '入金',
            h59: '出金'
        },
        bankcard: {
            b1: "銀行口座",
            b2: "金融機関名",
            b3: "金融機関名を入力してください",
            b4: "支店名",
            b5: "支店名を入力してください",
            b6: "支店番号",
            b7: "支店番号を入力してください",
            b8: "口座番号（普通）",
            b9: "アカウントを入力してください",
            b10: "アカウント名（仮名）",
            b11: "ユーザー名を入力してください",
            b12: "口座名義人",
            b13: "口座名義人を入力してください",
            b14: '縛る'
        },
        usdt: {
            u1: '私の注文',
            u2: '買う',
            u3: '契約時間を選択してください:',
            u4: '買取価格：',
            u5: '購入金額を入力してください',
            u6: 'もっと買う',
            u7: '買う量を減らす',
            u8: '操作が成功しました',
            u9:"少しでも",
            u10:'所得'
            

        },
        order: {
            o0: '私の注文',
            o1: '注文番号',
            o2: '注文タイプ',
            o3: '購入金額',
            o4: '販売金額',
            o5: '支払金額',
            o6: '契約時間',
            o7: '注文の状況',
            o8: '決済状況',
            o9: '決済時間',
            o10: '注文時間',
            o11: 'もっと買う',
            o12: '買う量を減らす',
            o13: '売った',
            o14: '利益',
            o15: '損失',
            o16: '有料',
            o17: '損益分岐点'
        },
        recharge: {

            r1: '保存された値',
            r2: '保存された値の金額',
            r3: '格納される値のタイプ',
            r4: '選択してください',
            r5: '入金アドレス',
            r6: '送金取引注文番号',
            r7: "転送しました",
            r8: "検討中",
            r9: '入力が正しくありません',
        },
        rechargeDetails: {
            r1: "保存値レコード",
            r2: "ストレージ値",
            r3: "検討中",
            r4: "再充電成功",
            r5: "値の保存に失敗しました",
        },

    }