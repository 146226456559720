export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },
        home: {
            h1: "Digital Cryptocurrency Bank",
            h2: "Invite friends",
            h3: "Bank account number",
            h4: "Participate in savings",
            h5: "Selected Financial",
            h6: "User Pool",
            h7: "Account",
            h8: "Account",
            h9: "valid node",
            h10: "Participant",
            h11: "profit",
            h12: "Profit Record",
            h13: "Address",
            h14: "number",
            h15: "FAQ",
            h16: "How do I join",
            h17: "Dear users, if you want to participate in USDT wallet savings, you need to strictly implement the platform's decisions on the premise of abiding by the platform user code, and accept the supervision of the platform's risk department. After successfully opening an account, you will automatically be granted deposit permission. Welcome. Thank you for joining.",
            h18: "How do I withdraw money?",
            h19: "The income you generate every day will be automatically deposited into your account balance. After the USDT withdrawal is successful, it will be automatically added to your USDT wallet account. For Vietnamese Dong withdrawals, please contact the online customer service center. Thank you for your participation and support.",
            h20: "How to calculate income?",
            h21: "When you join successfully, the savings contract will start and the income will start to be calculated.",
            h22: "Where do savings income come from?",
            h23: "Once the user participates in savings, the savings contract takes effect, and the user can receive four interest settlements every day, at 0:00, 6:00, 12:00, and 18:00. After the savings contract takes effect, the user's funds are in custody mode, and the funds are safe The platform is responsible. To ensure the safety of user account funds, please strictly abide by the platform rules and consult the online customer service center in time if you encounter any problems. Thank you.",
            h24: "How much is the bonus?",
            h25: "You can share my platform with your friends. If he joins, you can get 20% of his first deposit amount as a referral bonus, and you can always get 10% of his income as a referral commission. Your sharing company The link is in the upper right corner of the home page.",
            h26: "Account",
            h27: "Total income",
            h28: "Today's earnings",
            h29: "Financial management income",
            h30: "Withdraw cash",
            h31: "record",
            h32: "Available balance",
            h33: "Withdrawal amount",
            h34: "Please enter the withdrawal amount",
            h35: "all",
            h36: "Extract",
            h37: "Audit report",
            h38: "We have a security audit report",
            h39: "Partner",
            h40: "Our partners",
            h41: "View bank card number",
            h42: "Previous page",
            h43: "Next page",
            h44: "Already the first page",
            h45: "Please enter the withdrawal amount",
            h46: "Your balance is insufficient",
            h47: "Already the last page",
            h48: "income",
            h49: "Expenditure",
            h50: 'Excessive operation, please try again later!',
            h51: 'Audit Report',
            h52: 'Staking',
            h53: 'Order Trading',
            h54: 'Registration Reward',
            h55: 'Referral Reward',
            h56: 'Withdrawal',
            h57: 'Deposit',

            h58: 'Shangfen',
            h59: 'Lower points'
        },
        bankcard: {
            b1: "Bank Account",
            b2: "Bank name",
            b3: "Please enter the bank name",
            b4: "Bank branch",
            b5: "Please enter the bank branch",
            b6: "Branch number",
            b7: "Please enter the branch number",
            b8: "Account",
            b9: "Please enter your account number",
            b10: "Account name (hiragana)",
            b11: "Please enter your account name",
            b12: "Account name",
            b13: "Please enter the account name",
            b14: "bind",

        },
        usdt: {
            u1: 'My Order',
            u2: 'Buy',
            u3: 'Please select a contract time:',
            u4: 'purchasing price：',
            u5: 'Please enter the purchase amount',
            u6: 'buy more',
            u7: 'buy less',
            u8: 'Operation successful',
            u9:'least',
            u10:'income'
        },
        order: {
            o0: 'My Order',
            o1: 'Order number',
            o2: 'Order Type',
            o3: 'Buy amount',
            o4: 'Selling amount',
            o5: 'Payment amount',
            o6: 'contract time',
            o7: 'Order Status',
            o8: 'Settlement status',
            o9: 'Settlement time',
            o10: 'Order time',
            o11: 'buy more',
            o12: 'buy less',
            o13: 'sold',
            o14: 'profit',
            o15: 'loss',
            o16: 'Paid',
            o17: 'Break-even'
        },

        recharge: {
            r1: 'stored value',
            r2: 'Stored value amount',
            r3: 'Stored value type',
            r4: 'Please select',
            r5: 'deposit address',
            r6: 'Transfer transaction order number',
            r7: 'I have transferred',
            r8: 'under review',
            r9: 'Incorrect input',
        },
        rechargeDetails: {

            r1: "Stored value record",
            r2: "storage value",
            r3: "under review",
            r4: "Recharge successful",
            r5: "Failed to store value",
        },

    }